import { PLAYSTORE_PAGE2, THE_TECH_TRIUMPH_URL } from './../constants/GlobalConstants';
import {ICommonData} from "../../types/common";
import {FORM_ERRORS, FORM_REGEX} from "../constants/GrievanceForm";
import {currentPageURL, getCountryFromLocale, getUrlWithScheme, pageToRolloutChannelMapping} from "../constants/PagesConstants";
import {GRIEVANCE_FORM_TYPES} from "../enums/enums";
import {COUNTRY, EUROPE_COUNTRY} from "../constants/LocaleAndCountryConstants";
import { GET_APP_PLAY_URL, PLAYSTORE_PAGE } from "../constants/GlobalConstants";
import { getWebsiteEnvironment, WINZO_JOGOS_DOT_COM_BR, WINZO_GAMES_DOT_US, WINZO_JUEGOS_DOT_MX, WINZO_JUEGOS_DOT_COM_CO, WINZO_SPIELE_DOT_DE, LIVE_WEB_URL } from '../constants/EnvironmentConstants';
import { Environment } from '../../types/Misc.type';

export let buttonClickType = ""

export const setButtonClickType = (type: string) => {
  buttonClickType = type
}

export const validateForm = (data, error, formData) => {
    let hasError = error || false;
    if (data.type == GRIEVANCE_FORM_TYPES.FILE) {
        if (
            data.required &&
            (data.value == undefined || data.value.length === 0)
        ) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            data.error = "";
        }
        formData.push({
            question: data.label,
            answer: data.value,
            attachments: data.attachments,
            type: data.type,
            required: data.required
        });
    } else if (data.type == GRIEVANCE_FORM_TYPES.FORM) {
        data.attributes.forEach((el) => {
            [hasError, formData] = validateForm(el, hasError, formData) || [
                hasError,
                formData,
            ];
        });

        if (data.children) {
            const [childHasError, childData] = validateForm(data.children, hasError, formData)
            hasError = hasError || childHasError;
            formData = childData
        }

    } else if (data.type == GRIEVANCE_FORM_TYPES.RADIO) {
        if (data.required && data.selected == undefined) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            data.error = "";
        }
        formData.push({question: data.label, answer: data.value, type: data.type, required: data.required});
        if (data.selected != undefined && data.options[data.selected].children) {
            [hasError, formData] = validateForm(
                data.options[data.selected].children,
                hasError,
                formData
            ) || [hasError, formData];
        }
    } else {
        if (data.required && (data.value == undefined || data.value == "")) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            if (
                data.type == GRIEVANCE_FORM_TYPES.EMAIL &&
                !FORM_REGEX.EMAIL.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.EMAIL_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.MOBILE &&
                !FORM_REGEX.MOBILE.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.MOBILE_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.URL &&
                !FORM_REGEX.URL.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.URL_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.GOV_EMAIL &&
                !isGovEmail(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.EMAIL_ERROR_MESSAGE;
            } else {
                data.error = "";
            }
        }
        if (data.type !== GRIEVANCE_FORM_TYPES.PLAINTEXT) {
            let value = (data.type === GRIEVANCE_FORM_TYPES.DATE || data.type === GRIEVANCE_FORM_TYPES.DATETIME) ? new Date(data.value).toString() : data.value
            formData.push({question: data.label, answer: value, type: data.type, required: data.required});
        }
    }
    return [hasError, formData];
};

const isGovEmail = (email: string) => {
    if (FORM_REGEX.EMAIL.test(email) && (email.includes('nic.in') || email.includes('gov.in'))) {
        return true
    }
    return false
}

export const getFileNameFromUrl = (file: string) => {
    let fileNameArray = file.replace(/^.*[\\\/]/, "").split('_')
    return fileNameArray[fileNameArray.length - 1];
};

export const showHowToInstallVideo = (commonData: ICommonData, locale: string, utm?: string | string[] | undefined| null) => {
    if (
        currentPageURL !== PLAYSTORE_PAGE && currentPageURL !== PLAYSTORE_PAGE2 && currentPageURL !== GET_APP_PLAY_URL && 
        //TODO
        (commonData?.howToInstallVideo || commonData?.howToInstall?.howToInstallVideo)
    ) {
        return true;
    }
    return false;
}

export const showHowToInstallStory = (commonData: ICommonData, content: any) => {
    if (
        !pageToRolloutChannelMapping.PAID_APP.includes(currentPageURL) &&
        !pageToRolloutChannelMapping.PAID_APP_2.includes(currentPageURL) &&
        !pageToRolloutChannelMapping.AFFILIATE_APP.includes(currentPageURL) &&
        !pageToRolloutChannelMapping.ORGANIC_APP.includes(currentPageURL) && 
        (content.howToInstallStory || commonData.howToInstallStory) 
    ) {
        return true;
    }
    return false;
}

export const multipleClassName = (...classNames: string[]): string => {
  return classNames.join(" ")
}

export const getLogoUrl = (locale?: string, pageName?: string) => {
    if (pageName?.includes(THE_TECH_TRIUMPH_URL)) {
        return "https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/ttt-logo.webp";
    }

  if (getCountryFromLocale(locale) === COUNTRY.BRAZIL || getCountryFromLocale(locale) === COUNTRY.GERMANY) {
      return "https://d3g4wmezrjkwkg.cloudfront.net/website/images/logoAsset_br.webp"
  } else {
      return "https://d3g4wmezrjkwkg.cloudfront.net/website/images/logo-gold.png"
  }
}

export const getDynamicString = (field: string, stringKey: string) => {
    return stringKey.replace("%s", field)
}

export const checkUppercase = (str: string) => {
    return /[A-Z]/.test(str);
};

export const getOneLinkClickUrl = (clickUrl: string, url: string): string => {
    if (clickUrl) {
        const oneLinkUrl = new URL(clickUrl)
        oneLinkUrl.searchParams.set("af_android_url", url)
        return oneLinkUrl.toString()
    } else {
        return clickUrl
    }
}

export const getDomainEnvForLocale = (country: string): Environment => {
    let basePath = getUrlWithScheme(getWebsiteEnvironment().baseUrl);

    if (country === COUNTRY.BRAZIL) {
        basePath = getUrlWithScheme(WINZO_JOGOS_DOT_COM_BR);
    } else if (country === COUNTRY.US || Object.values(EUROPE_COUNTRY).includes(country)) {
        basePath = getUrlWithScheme(WINZO_GAMES_DOT_US);
    } else if (country === COUNTRY.MEXICO) {
        basePath = getUrlWithScheme(WINZO_JUEGOS_DOT_MX);
    } else if (country === COUNTRY.COLOMBIA) {
        basePath = getUrlWithScheme(WINZO_JUEGOS_DOT_COM_CO);
    } else if (country === COUNTRY.GERMANY) {
        basePath = getUrlWithScheme(WINZO_SPIELE_DOT_DE);
    } else {
        basePath = getUrlWithScheme(LIVE_WEB_URL);
    }
    return getWebsiteEnvironment(basePath)
}